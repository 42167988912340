import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './layout.css';
import 'animate.css';

const Layout = ({ children }) => (
  <div>
    <Helmet
      title={"Dorian Dominguez - Portfolio"}
    />
    {children}
  </div>
);

export default Layout;

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
