import React from 'react';

import Header from '../components/Header';
import Hero from '../components/Hero';
import AboutSection from '../components/AboutSection';
import ProjectsSection from '../components/ProjectsSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import Layout from '../components/layout';

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <div className="container">
      <Hero />
      <AboutSection />
      <ProjectsSection />
      <ContactSection />
      <Footer />
      </div>
    </Layout>
  );
};

export default IndexPage;
