import React, { useEffect } from 'react';
import styled from 'styled-components';

import * as Styles from '../layouts/styles-constants';
import TypedText from './TypedText';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

import SmallImage from '../images/about-pic-1-2.png';

const AboutSection = () => {
  const AboutContainer = styled.div`
    // background-image: ${Styles.COLOR_GRADIENT};
    font-family: ${Styles.FONT_TERTIARY};
    margin-bottom: 2rem;
    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
      'heading'
      'image'
      'list';

    ${Styles.SCREEN_LARGE} {
      grid-template-columns: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'image heading'
        'image list';
    }
  `;

  const AboutImageContainer = styled.div`
    grid-area: image;
    place-self: center;
  `;

  const AboutImage = styled.img`
    // @media screen and (max-width: 575px) {
    //   max-width: 320px;
    // }
  `;
  const Wave = styled.span`
    display: inline-block;
    transform: scale(-1, 1);
  `;

  const TypistHeader = styled.div`
    color: ${Styles.COLOR_TERTIARY};
  `;

  const AboutTextContainer = styled.div`
    padding: 4rem 4rem 0 4rem;
    grid-area: content;
    place-self: center;

    ${Styles.SCREEN_SMALL} {
      padding: 1rem 2rem;
    }
  `;

  const AboutHeading = styled.h2`
    color: ${Styles.COLOR_PRIMARY};
    grid-area: heading;
    text-align: center;
    font-size: 3rem;
    margin-top: auto;

    ${Styles.SCREEN_SMALL} {
      font-size: 4rem;
    }

    ${Styles.SCREEN_MEDIUM} {
      font-size: 4.5rem;
    }

    ${Styles.SCREEN_LARGE} {
      font-size: 4rem;
      margin-bottom: 0;
    }
    
    ${Styles.SCREEN_XLARGE} {
      font-size: 5rem;
    }

    ${Styles.SCREEN_LARGEST} {
      font-size: 6rem;
    }
  `;

  const AboutText = styled.p`
    // font-size: 1.2rem;
    color: ${Styles.COLOR_WHITE};
  `;

  const AboutListWrapper = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const AboutHeadingWrapper = styled.div`
    margin-top: auto;
  `;

  const AboutList = styled.ul`
    grid-area: list;
    list-style: none;
    color: ${Styles.COLOR_WHITE};
    text-align: left;
    margin: 0 1rem 0 1rem;
    font-size: 1.06rem;
    li {
      &::before {
        content: '💼 ';
        margin-right: 0.5rem;
      }
      &:nth-child(2)::before {
        content: '💻 ';
      }
      &:nth-child(3)::before {
        content: '🎨 ';
      }
      &:nth-child(4)::before {
        content: '👨‍💻 ';
      }
      &:nth-child(5)::before {
        content: '💾 ';
      }
    }

    ${Styles.SCREEN_XSMALL} {
      font-size: 1.25rem;
    }

    ${Styles.SCREEN_SMALL} {
      font-size: 2rem;
    }

    ${Styles.SCREEN_MEDIUM} {
      font-size: 2.5rem;
    }

    ${Styles.SCREEN_LARGE} {
      font-size: 1.72rem;
    }

    ${Styles.SCREEN_XLARGE} {
      font-size: 2.2rem;
    }

    ${Styles.SCREEN_XXLARGE} {
      font-size: 2.5rem;
    }

    ${Styles.SCREEN_LARGEST} {
      font-size: 2.9rem;
    }
  `;

  return (
    <AboutContainer id="about">
      <AboutImageContainer className="img-container">
        <Bounce left big duration={1500}>
          <AboutImage src={SmallImage} />
        </Bounce>
      </AboutImageContainer>
      <AboutHeadingWrapper>
        <Zoom top duration={1500}>
          <AboutHeading>
            Hi{' '}
            <Wave className="animate__animated animate__tada animate__infinite animate__slow animate__delay-2s">
              👋
            </Wave>{' '}
            I'm Dorian
          </AboutHeading>
        </Zoom>
      </AboutHeadingWrapper>
      <AboutListWrapper>
        <AboutList>
          <Zoom bottom duration={2000}>
            <li> 6 years working in web development</li>
          </Zoom>
          <Zoom bottom duration={2000}>
            <li> Frontend specialized/full stack capable</li>
          </Zoom>
          <Zoom bottom duration={2000}>
            <li> UI/UX design, wireframing & prototyping</li>
          </Zoom>
          <Zoom bottom duration={2000}>
            <li> JavaScript, TypeScript, Angular, React</li>
          </Zoom>
          <Zoom bottom duration={2000}>
            <li> Java, Spring, SQL, AEM, WordPress</li>
          </Zoom>
          {/* <li>🥋 Jiu Jitsu Purple Belt</li> */}
          {/* <li>🕹 Video game connoisseur</li> */}
        </AboutList>
      </AboutListWrapper>
      {/* <TypistHeader>
          <TypedTextWrapper>
          <TypedText
            strings={[
              'building cool stuff',
              'working with smart people',
              'front-end development',
              'learning new things',
              'pizza and beer',
              'practicing jiu jitsu',
              'playing video games',
              'the occasional hike',
              'code and coffee',
            ]}
          />
          </TypedTextWrapper>
        </TypistHeader> */}
      {/* <AboutTextContainer className="about-text__container">
        <AboutText>
          I am a web developer at Caesars Entertainment in Las Vegas. I help
          maintain and create custom components for our enterprise content
          management system that's used to author over 50+ property, casino and
          market sites.
        </AboutText>
        <AboutText>
          Front-end development is my strong suit but I am experienced working
          in a full-stack environment. I regularly work with Kotlin,
          JavaScript/jQuery, Less in Adobe Experience Manager. Recently I
          started working with React and TypeScript to create new components for
          Caesars.com homepage redesign.
        </AboutText>
        <AboutText>
          I am always eager to learn new things and grow as a developer.
          Currently I am working on improving my UX/UI skills, writing more
          server side code and learning about data structures and algorithms.
          When I'm not coding I enjoy hanging out with my family, training Jiu
          Jitsu, playing video games and the occasional outdoor adventure.
        </AboutText>
      </AboutTextContainer> */}
    </AboutContainer>
  );
};

export default AboutSection;
