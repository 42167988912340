import React from 'react';
import styled from 'styled-components';

import Beer from '../images/beer-emoji.png';
import Coffee from '../images/coffee-emoji.png';
import ReactLogo from '../images/React.png';
import GatsbyLogo from '../images/gatsby.png';
import * as Styles from '../layouts/styles-constants';

const FooterContainer = styled.footer`
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  color: ${Styles.COLOR_WHITE};
`;

const FooterText = styled.p`
  // font-size: 0.8rem;
  margin: 0 auto;
`;

const FooterEmoji = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: -4px;
  ${Styles.SCREEN_MEDIUM} {
    margin: 0 auto;
  }
`;

const CopyrightText = styled.small`
  align-self: flex-end;
  font-size: 0.7rem;
  margin: 0 auto;
`;

class Footer extends React.Component {
  render() {
    return (
      <FooterContainer>
        <FooterText>
          Built using{' '}
          <a href="https://reactjs.org" target="_blank">
            <FooterEmoji src={ReactLogo} />
          </a>{' '}
          and{' '}
          <a href="https://gatsbyjs.com" target="_blank">
            <FooterEmoji src={GatsbyLogo} />
          </a>{' '}
          with help from <FooterEmoji src={Coffee} /> and{' '}
          <FooterEmoji src={Beer} />
        </FooterText>
        <CopyrightText>
          DorianDominguez.com &copy; {new Date().getFullYear()}
        </CopyrightText>
      </FooterContainer>
    );
  }
}

export default Footer;
