import React from "react";
import Typed from "typed.js";

class TypedText extends React.Component {
  componentDidMount() {
    const { strings } = this.props;
    const options = {
      strings: strings,
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 1500,
      loop: true,
      showCursor: true,
      cursorChar: "_",
    };
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <span
        ref={el => {
          this.el = el;
        }}
      />
    );
  }
}

export default TypedText;
