import React from 'react';
import styled from 'styled-components';
import * as Styles from '../layouts/styles-constants';

const HeroBackground = styled.div`
    height: 100vh;
  }
`;

const HeroContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
`;

const HeroTextContainer = styled.div`
  font-family: ${Styles.FONT_HEADING};
  text-align: center;
  margin: auto 0;
  flex: 1 1 100%;
  text-transform: uppercase;
`;

const TitleText = styled.h1`
  color: ${Styles.COLOR_PRIMARY};
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-weight: 400;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em ${Styles.COLOR_PRIMARY},
    0 0 0.5em ${Styles.COLOR_PRIMARY}, 0 0 0.1em ${Styles.COLOR_PRIMARY},
    0 10px 3px #000;

  ${Styles.SCREEN_SMALL} {
    font-size: 3.5rem;
  }

  ${Styles.SCREEN_MEDIUM} {
    font-size: 4.5rem;
  }

  ${Styles.SCREEN_LARGE} {
    font-size: 5.5rem;
  }

  ${Styles.SCREEN_XLARGE} {
    font-size: 6.5rem;
  }

  ${Styles.SCREEN_XXLARGE} {
    font-size: 7.5rem;
  }

  ${Styles.SCREEN_LARGEST} {
    font-size: 9rem;
  }
`;

const SlashSpan = styled.span`
  font-size: 2.5rem;

  ${Styles.SCREEN_SMALL} {
    font-size: 3.5rem;
  }

  ${Styles.SCREEN_MEDIUM} {
    font-size: 4rem;
  }

  ${Styles.SCREEN_LARGE} {
    font-size: 5.5rem;
  }

  ${Styles.SCREEN_XLARGE} {
    font-size: 6.5rem;
  }

  ${Styles.SCREEN_XXLARGE} {
    font-size: 8rem;
  }

  ${Styles.SCREEN_LARGEST} {
    font-size: 9rem;
  }
`

const blinkerKeyFrame = `  
@keyframes blinker {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}`;

const InitBlink = styled.span`
  animation: blink 3s linear 1;
  @keyframes blink {
    30% {
      color: #333;
      text-shadow: none;
    }
    35% {
      color: inherit;
      text-shadow: inherit;
    }
    41% {
      color: #333;
    }
    42% {
      text-shadow: none;
    }
    45% {
      color: inherit;
      text-shadow: inherit;
    }
    50% {
      color: #333;
      text-shadow: none;
    }
    55% {
      color: inherit;
      text-shadow: inherit;
    }
  }
`;

const ShortBlink = styled.span`
  animation: blinker linear infinite 3s;
  ${blinkerKeyFrame}
`;

const ShorterBlink = styled.span`
  animation: blinker linear infinite 2s;
  ${blinkerKeyFrame}
`;

const SubTitleText = styled.h3`
  margin: 0;
  font-weight: 400;
  color: ${Styles.COLOR_TERTIARY};
  font-size: 2rem;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em ${Styles.COLOR_TERTIARY},
    0 0 0.5em ${Styles.COLOR_TERTIARY}, 0 0 0.1em ${Styles.COLOR_TERTIARY},
    0 10px 3px #000;

  ${Styles.SCREEN_SMALL} {
    font-size: 2.5rem;
  }

  ${Styles.SCREEN_MEDIUM} {
    font-size: 3rem;
  }

  ${Styles.SCREEN_LARGE} {
    font-size: 4rem;
  }

  ${Styles.SCREEN_XLARGE} {
    font-size: 5rem;
  }

  ${Styles.SCREEN_XXLARGE} {
    font-size: 6rem;
  }

  ${Styles.SCREEN_LARGEST} {
    font-size: 6.5rem;
  }
`;

const ScrollDownContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScrollDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  background-color: ${Styles.COLOR_SECONDARY};
  border: 2px solid ${Styles.COLOR_TERTIARY};

  ${Styles.SCREEN_SMALL} {
    height: 4rem;
    width: 4rem;
  }

  ${Styles.SCREEN_LARGE} {
    height: 4.5rem;
    width: 4.5rem;
  }

  ${Styles.SCREEN_LARGEST} {
    height: 5.5rem;
    width: 5.5rem;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid ${Styles.COLOR_PRIMARY};
    transition: 0.3s;
  }
`;

const DownArrow = styled.i`
  color: ${Styles.COLOR_PRIMARY};
  &:hover {
    color: ${Styles.COLOR_TERTIARY};
    transition: 0.3s;
  }

  ${Styles.SCREEN_SMALL} {
    font-size: 1.5rem;
  }

  ${Styles.SCREEN_LARGE} {
    font-size: 1.8rem;
  }

  ${Styles.SCREEN_LARGEST} {
    font-size: 2.2rem;
  }
`;

const scrollToTools = () => {
  document.querySelector('#about').scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

class Hero extends React.Component {
  render() {
    return (
      <HeroBackground id="home">
        <HeroContentContainer>
          <HeroTextContainer>
            <TitleText>
              <InitBlink>
                D<ShorterBlink>o</ShorterBlink>rian Dominguez
              </InitBlink>
            </TitleText>
            <SubTitleText>
              <InitBlink>
                UI <SlashSpan>/</SlashSpan> UX Develop<ShortBlink>e</ShortBlink>r
              </InitBlink>
            </SubTitleText>
          </HeroTextContainer>
          <ScrollDownContainer className="scroll-down__container">
            <a onClick={scrollToTools}>
              <ScrollDown>
                <DownArrow className="fa fa-angle-double-down" />
              </ScrollDown>
            </a>
          </ScrollDownContainer>
        </HeroContentContainer>
      </HeroBackground>
    );
  }
}

export default Hero;
