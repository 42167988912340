import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Zoom from 'react-reveal/Zoom';

const IconsContainer = styled.div`
  display: flex;
`;

const SocialIcons = () => {
  return (
    <IconsContainer>
      <Zoom delay={1500}>
        <Icon
          iconHref={'https://github.com/dorian-dominguez'}
          classes={'fab fa-github-square'}
          text={'GitHub'}
        />
      </Zoom>
      <Zoom delay={1000}>
        <Icon
          iconHref={'https://www.linkedin.com/in/dorian-dominguez'}
          classes={'fab fa-linkedin'}
          text={'LinkedIn'}
        />
      </Zoom>
      <Zoom delay={500}>
        <Icon
          iconHref={'/Dorian_Dominguez_Resume.pdf'}
          classes={'fas fa-file-alt'}
          text={'Resume'}
        />
      </Zoom>
    </IconsContainer>
  );
};

export default SocialIcons;
