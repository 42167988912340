import React from 'react';
import styled from 'styled-components';
import * as Styles from '../layouts/styles-constants';
import Fade from 'react-reveal/Fade';

const ProjectContainer = styled.div`
  display: grid;
  place-items: center;
  height: auto;
  align-items: center;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'title'
    'image'
    'description'
    'link';
  border-radius: 4px;
  margin: 1rem;

  ${Styles.SCREEN_SMALL} {
    margin: 2rem;
  }
`;

const ProjectImageContainer = styled.img`
  grid-area: image;
  margin-top: 1rem;
`;

const ProjectTitle = styled.h3`
  font-family: ${Styles.FONT_TERTIARY};
  color: ${Styles.COLOR_PRIMARY};
  border-bottom: 1px solid ${Styles.COLOR_TERTIARY};
  grid-area: title;
  margin: 0;
  font-size: 1.8rem;

  ${Styles.SCREEN_SMALL} {
    font-size: 2.5rem;
  }

  ${Styles.SCREEN_MEDIUM} {
    font-size: 3rem;
  }

  ${Styles.SCREEN_XXLARGE} {
    font-size: 3.5rem;
  }

  ${Styles.SCREEN_LARGEST} {
    font-size: 4rem;
  }
`;

const ProjectDescription = styled.div`
  color: ${Styles.COLOR_WHITE};
  grid-area: description;
`;

const ProjectSummary = styled.p`
  font-size: 1rem;

  ${Styles.SCREEN_SMALL} {
    font-size: 1.2rem;
  }

  ${Styles.SCREEN_MEDIUM} {
    font-size: 1.5rem;
  }

  ${Styles.SCREEN_XLARGE} {
    font-size: 1.2rem;
  }

  ${Styles.SCREEN_XLARGE} {
    font-size: 1.5rem;
  }
`;

const ProjectHighlights = styled.p`
  font-size: 0.8rem;
  color: ${Styles.COLOR_PRIMARY};

  ${Styles.SCREEN_SMALL} {
    font-size: 1rem;
  }

  ${Styles.SCREEN_MEDIUM} {
    font-size: 1.2rem;
  }

  ${Styles.SCREEN_XLARGE} {
    font-size: 1rem;
  }

  ${Styles.SCREEN_XXLARGE} {
    font-size: 1.2rem;
  }
`;

const LinkContainer = styled.div`
  grid-area: link;
`;

const ProjectLink = styled.p`
  display: inline-block;
  border: 0.3rem solid ${Styles.COLOR_CTA};
  border-radius: 2rem;
  padding: 0.5rem;
  color: ${Styles.COLOR_WHITE};
  font-weight: 600;
  text-decoration: none;

  &:hover {
    color: ${Styles.COLOR_WHITE};
    background: ${Styles.COLOR_CTA};
    transition: 0.5s;
  }

  &:link,
  &:visited {
    color: ${Styles.COLOR_CTA};
  }
`;

const Project = (props) => {
  const { title, projectImg, projectDescription, techUsed, projectLink } =
    props;

  return (
    <ProjectContainer>
      <ProjectTitle>{title}</ProjectTitle>
      <Fade bottom>
        <ProjectImageContainer src={projectImg} />
      </Fade>
      <ProjectDescription>
        <ProjectSummary>{projectDescription}</ProjectSummary>
        <ProjectHighlights>{techUsed}</ProjectHighlights>
      </ProjectDescription>
      {/* {projectLink ? (
        <LinkContainer>
          <a target="_blank" href={projectLink}>
            <ProjectLink>Check it out</ProjectLink>
          </a>
        </LinkContainer>
      ) : (
        <div style={{ height: "4rem" }} />
      )} */}
    </ProjectContainer>
  );
};

export default Project;
