import React from "react";
import styled from "styled-components";
import * as Styles from "../layouts/styles-constants";

const IconAnchor = styled.a`
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  margin: 0 0.2rem;
`;

const IconStyled = styled.i`
  color: ${Styles.COLOR_TERTIARY};
  font-size: 1.7rem;

  &.resume-icon {
    font-size: 1.4rem;
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${Styles.COLOR_PRIMARY};
    transition: 0.3s;
  }
`;

const IconText = styled.small`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${Styles.COLOR_PRIMARY};
`;

const Icon = props => {
  return (
    <IconAnchor href={props.iconHref} target="_blank">
      <IconStyled className={props.classes} />
      <IconText>{props.text}</IconText>
    </IconAnchor>
  );
};

export default Icon;
