// amazon test id 23280666109321

import React from 'react';
import styled from 'styled-components';
import * as Styles from '../layouts/styles-constants';

import SectionHeading from '../components/SectionHeading';
import Project from '../components/Project';
import CaesarsHomepage from '../images/caesars-home-0.png';
import WebUI from '../images/webui-0.png';
import CareWeb from '../images/care-0.png';
import ChangeHealthcare from '../images/change-healthcare.png';
import Hilti from '../images/hilti.png';
import TotalRewards from '../images/total-rewards-0.png';
import TotalVegas from '../images/total-vegas-0.png';
import LinqScreens from '../images/linq-screens-0.png';

const ProjectsContainer = styled.div`
  margin: 2rem 0;
`;

const Projects = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  grid-template-columns: auto;
  grid-gap: 1rem;
  
  ${Styles.SCREEN_MEDIUM} {
    grid-gap: 2rem;
  }
  
  ${Styles.SCREEN_XLARGE} {
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
`;

class ProjectsSection extends React.Component {
  render() {
    return (
      <ProjectsContainer id="projects">
        <SectionHeading heading={"Portfolio"} />
        <Projects>
          <Project
            title={'Change Healthcare'}
            projectImg={ChangeHealthcare}
            projectDescription="Responsible for creating and improving user interface elements and building custom reusable AEM 6.5 components to enhance user experience on the main company website."
            techUsed="AEM 6.5 &bull; JavaScript &bull; Figma &bull; Adobe Target &bull; Less &bull; BEM &bull; HTL/Sightly &bull; Java"
          />
          <Project
            title={'Hilti'}
            projectImg={Hilti}
            projectDescription="Worked closely with business analysis and design to implement an enormous, large scale legacy redesign project. Developed new reusable UI components, enhanced designs and optimized frontend codebase."
            techUsed="AEM 6.5 &bull; JavaScript &bull; jQuery &bull; Figma &bull; Storybook &bull; Less &bull; BEM &bull; HTL/Sightly &bull; Java"
          />
          <Project
            title={'CARE Web'}
            projectImg={CareWeb}
            projectDescription="CARE modernization project where I'm helping take an outdated case management system used to assess ~75,000 citizens and converting it into a progressive web app (PWA)."
            techUsed="Angular 13 &bull; TypeScript &bull; SCSS &bull; Balsamiq &bull; Bootstrap &bull; Java &bull; Spring &bull; SQL &bull; Azure &bull; Jenkins"
          />
          <Project
            title={'WebUI'}
            projectImg={WebUI}
            projectDescription="Developed and maintained custom Angular components for a private frontend framework that's used across multiple internal applications such as CARE, TIVA2, STARS and ASAP."
            techUsed="Angular 13 &bull; TypeScript &bull; SCSS &bull; Balsamiq &bull; Bootstrap &bull; Java &bull; Spring &bull; SQL &bull; Azure &bull; Jenkins"
          />
          <Project
            title={'Caesars Homepage'}
            projectImg={CaesarsHomepage}
            projectDescription="Created custom components to render unique content based off of user's previous visits with Adobe Target Recommendations for Ceasars Entertainment's homepage."
            techUsed="React &bull; TypeScript &bull; AEM 6.4 &bull; Adobe Target &bull; Less &bull; BEM &bull; HTL/Sightly &bull; Java"
          />
          <Project
            title={'The Book @ The Linq'}
            projectImg={LinqScreens}
            projectDescription="Developed a modern UI using CSS Grid, Flexbox, JavaScript and AEM Screens to provide patrons at The Linq Hotel & Casino live up to date stats, betting odds and leaderboards."
            techUsed="JavaScript &bull; GraphQL &bull; HTL/Sightly &bull; AEM 6.3 &bull; Adobe Screens &bull; Less &bull; BEM"
          />
          <Project
            title={'Total Vegas'}
            projectImg={TotalVegas}
            projectDescription="Built custom components for Caesars Las Vegas Market page redesign project. Aided in bug bashing to insure project met completion date."
            techUsed="Kotlin &bull; TypeScript &bull; jQuery &bull; AEM 6.2 &bull; Less &bull; BEM"
          />
          <Project
            title={'Caesars Rewards'}
            projectImg={TotalRewards}
            projectDescription="This project involved creating a new AEM components for Caesars Rewards page and converting AEM from 5.6 to 6.2."
            techUsed="Kotlin &bull; JavaScript &bull; jQuery &bull; AEM 6.2 &bull; JSP &bull; Less"
          />
        </Projects>
      </ProjectsContainer>
    );
  }
}

export default ProjectsSection;
