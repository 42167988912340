import React from 'react';
import Headroom from 'react-headroom';
import styled from 'styled-components';
import * as Styles from '../layouts/styles-constants';
import SocialLinks from './SocialLinks';
import Zoom from 'react-reveal/Zoom';

const headerStyles = {
  backgroundImage: `${Styles.COLOR_GRADIENT}`,
  background: `${Styles.COLOR_SECONDARY}`,
  padding: '0.5rem',
  borderBottom: `2px solid ${Styles.COLOR_PRIMARY}`,
};

const SiteHeader = styled.header`
  color: ${Styles.COLOR_PRIMARY};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  
  ${Styles.SCREEN_MEDIUM} {
    flex-direction: row;
  }
`;

const Nav = styled.nav`
  display: flex;
`;

const NavItems = styled.ul`
  display: flex;
  margin: 0;
`;

const NavItem = styled.li`
  text-transform: uppercase;
  margin: 0 0.5rem;
  text-decoration: none;
  list-style: none;
  font-size: 1.5rem;
  font-family: ${Styles.FONT_TERTIARY};
  font-weight: 400;

  ${Styles.SCREEN_MEDIUM} {
    font-size: 2rem;
  }

  &:hover {
    cursor: pointer;
    color: ${Styles.COLOR_TERTIARY};
    transition: 0.3s !important;
  }
`;

const scrollSettings = {
  behavior: 'smooth',
  block: 'start',
  inline: 'nearest',
};

const scrollToTools = () => {
  document.querySelector('#about').scrollIntoView(scrollSettings);
};

const scrollToProjects = () => {
  document.querySelector('#projects').scrollIntoView(scrollSettings);
};

const scrollToContact = () => {
  document.querySelector('#contact').scrollIntoView(scrollSettings);
};

class Header extends React.Component {
  render() {
    return (
      <Headroom style={headerStyles}>
        <SiteHeader>
          <Nav>
            <NavItems>
              <Zoom delay={500}>
                <NavItem onClick={scrollToTools}>About</NavItem>
              </Zoom>
              <Zoom delay={1000}>
                <NavItem onClick={scrollToProjects}>Portfolio</NavItem>
              </Zoom>
              <Zoom delay={1500}>
                <NavItem onClick={scrollToContact}>Contact</NavItem>
              </Zoom>
            </NavItems>
          </Nav>
          <SocialLinks />
        </SiteHeader>
      </Headroom>
    );
  }
}

export default Header;
