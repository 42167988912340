import React from "react";
import styled from "styled-components";
import * as Styles from "../layouts/styles-constants";

import SectonHeading from "./SectionHeading";

const ContactSectionContainer = styled.section`
  max-width: 1240px;
  margin: 0 auto 4rem;
`;

const ContactFormOuter = styled.div`
  background-image: ${Styles.COLOR_GRADIENT};
  padding: 2rem;
  border: 0.8rem solid ${Styles.COLOR_PRIMARY};
  border-radius: 0.6rem;
  margin: 2rem 2rem;
  &:focus {
    outline: none;
    border: none;
    color: ${Styles.COLOR_LIGHT_GRAY};
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Styles.COLOR_PRIMARY};
  &:focus {
    outline: none;
    border: none;
    color: ${Styles.COLOR_LIGHT_GRAY};
  }
`;

const FormInputField = styled.input`
  width: 100%;
  color: ${Styles.COLOR_LIGHT_GRAY};
  border: 0;
  padding: 0;
  // font-size: 1rem;
  overflow: auto;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  resize: none;

  &:focus {
    border-color: none;
    box-shadow: none;
    color: ${Styles.COLOR_PRIMARY};
  }
`;

const FormMessage = styled.textarea`
  height: 200px;
  width: 100%;
  color: ${Styles.COLOR_LIGHT_GRAY};
  border: 0;
  padding: 0;
  // font-size: 1rem;
  overflow: auto;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  resize: none;

  &:focus {
    border-color: none;
    box-shadow: none;
    color: ${Styles.COLOR_PRIMARY};
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled.input`
  background-color: transparent;
  border: 0.2rem solid ${Styles.COLOR_TERTIARY};
  border-radius: 2rem;
  color: ${Styles.COLOR_PRIMARY};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  // font-size: 1rem;
  padding: 0.6rem;
  font-weight: bold;

  &:hover {
    background: ${Styles.COLOR_TERTIARY};
    border-color: ${Styles.COLOR_PRIMARY};
    transition: 0.6s;
    cursor: pointer;
  }
`;

class ContactSection extends React.Component {
  render() {
    return (
      <ContactSectionContainer id="contact">
        <SectonHeading heading={"Drop me a line"} />
        <ContactFormOuter>
          <form
            role="form"
            action="https://formspree.io/doriandevelops@gmail.com"
            method="POST"
          >
            <FormGroup>
              <FormInputField
                type="text"
                name="name"
                placeholder="Name...*"
                required
              />
            </FormGroup>

            <FormGroup>
              <FormInputField
                type="email"
                placeholder="Email...*"
                name="_replyto"
                required
              />
            </FormGroup>

            <FormGroup>
              <input
                type="hidden"
                name="_subject"
                value="New message from your portfolio site."
              />
              <FormInputField
                type="text"
                name="subject"
                placeholder="Subject..."
              />
            </FormGroup>

            <FormGroup>
              <FormMessage
                name="message"
                placeholder="Please Enter Your Message...*"
                required
              />
            </FormGroup>
            <SubmitButtonContainer>
              <SubmitButton className="animate__animated animate__pulse animate__infinite animate__slow" type="submit" value="Send message" />
              <input
                type="hidden"
                name="_next"
                value="https://doriandevelops.github.io/portfolio"
              />
            </SubmitButtonContainer>
          </form>
        </ContactFormOuter>
      </ContactSectionContainer>
    );
  }
}

export default ContactSection;
